import { A, navigate } from 'hookrouter';
import {useState,useEffect} from 'react';
import { ssprefix, endpoint } from './Config';

export default function Classi(props) {

    const [classi, setClassi] = useState(JSON.parse(sessionStorage.getItem(ssprefix+"elencoclassi"))||[]);
    const [addDisabled, setAddDisabled] = useState(false);

    useEffect(() => {

        if (classi.length > 0 && classi[0].classe !== null) {
            let stato_sezioni = JSON.parse(sessionStorage.getItem(ssprefix+"statosezioni"));
            stato_sezioni[3] = true;
            sessionStorage.setItem(ssprefix+"statosezioni",JSON.stringify(stato_sezioni))
        } else {
            let stato_sezioni = JSON.parse(sessionStorage.getItem(ssprefix+"statosezioni"));
            stato_sezioni[3] = false;
            sessionStorage.setItem(ssprefix+"statosezioni",JSON.stringify(stato_sezioni))
        }

        sessionStorage.setItem(ssprefix+"elencoclassi", JSON.stringify(classi));
    },[classi])

    useEffect(() => {
        if (!sessionStorage.getItem(ssprefix + "domandecustom")) {

        
            fetch(endpoint + "&task=apply.getDomande&uid="+props.uid)
            .then(response => {
                if (!response.ok) {
                    console.error("Errore chiamata ad API")
                    window.location = "./"+props.uid;
                } else  {
                return response.json();
                }
            })
            .then(data => {
                sessionStorage.setItem(ssprefix + "domandecustom", JSON.stringify(data))
            });
        } 
    },[props.uid])

    const ElencoClassi = function() {
    
        const aggiungiClasse = () => {
            setAddDisabled(true)
            const nc = classi.length;
            setClassi([...classi, {classe:null, nalunni :null}])
            setTimeout(() => navigate("/"+props.uid+"/classe/"+nc),1000)
        }
    
        return  (<table className="table">
            <thead>
                <tr>
                    <th>Classe</th>
                    <th>N. Alunni</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {classi.map((classe,index) => (
                <tr key={index}>
                    <td>{classe.classe || (<i class="bi bi-patch-question"></i>)}</td>
                    <td>{classe.nalunni || (<i class="bi bi-patch-question"></i>)}</td>
                    <td className="d-flex justify-content-end">
                        <A href={"./classe/"+index} className="btn btn-sm btn-primary" style={{marginRight:".5rem"}}><i className="bi bi-pencil-square"></i> Modifica</A>
                        <button onClick={() => { const c2 = Object.assign([],classi); c2.splice(index,1); setClassi(c2) }} className="btn btn-sm btn-danger"><i className="bi bi-trash"></i> Elimina</button>
                    </td>
                </tr>))}
                {classi.length === 0 ? (<tr><td className="text-center" colSpan="3"><small>Nessuna classe inserita</small></td></tr>): ""}
            </tbody>
            <tfoot>
                <tr><td colspan="3"><button onClick={aggiungiClasse} className="btn btn-sm btn-outline-primary" disabled={addDisabled}><i className="bi bi-plus"></i> Aggiungi classe</button></td></tr>
            </tfoot>
        </table>)
        }

    return (

        <div className="sp_form">
            <h2>Le tue classi</h2>
            <p>Quali classi parteciperanno al progetto?</p>
            
            <div className="table-responsive">
                <ElencoClassi/>
            </div>
            
            { classi.length > 0 ? (<A href={"/"+props.uid+"/utente-scuolapark"} className="btn btn-primary"><i className="bi bi-arrow-right"></i> Continua</A>) : ""}

        </div>

    )
}