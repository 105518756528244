import {navigate} from 'hookrouter';
import React, {useState, useEffect} from 'react';
import {ErrorMessage, Formik,  Form, Field} from 'formik';


import { ssprefix, endpoint } from './Config';


export default function UtenteEduCO(props) {

  const form_name = ssprefix+"utentescuolapark";

  const emaildocente = JSON.parse(sessionStorage.getItem(ssprefix+"anagraficadocente")).email;
 

  let initial = {email: emaildocente, password: ''};
  if (sessionStorage.getItem(form_name) !== null) {
    initial = JSON.parse(sessionStorage.getItem(form_name));
  }

  const [contenuto, setContenuto] = useState(<div className="text-center py-5">Caricamento in corso...<br/><br/><div className="spinner-border text-primary" role="status">
  <span className="visually-hidden">Loading...</span>
</div></div>)

  const [feedbackAuth, setFeedbackAuth] = useState("");
  
  useEffect(() => {
    fetch(endpoint + "&task=apply.checkUser&email="+emaildocente)
    .then(response => {
      if (!response.ok) {
        alert("Errore");
      } else  {
        return response.json();
      }
    })
    .then(data => {
      if (data.exists === true) {
        setContenuto(gia_registrato)
      } else {
        setContenuto(nuovo_utente)
      }
    });
  },[])

  const nuovo_utente = (
        <div class="sp_form">
          <h2>Crea il tuo account</h2>
          <p>Registrati a EduCO</p>

          
          <div className="card ">
            <div className="card-body">
             
            
          <Formik
            initialValues={initial}
            validate={values => {
              const errors = {}
              
              // if (values.password.length < 5) {
              //   errors.password = "Password troppo corta!";
              // }


              if (
                values.password.length < 6 ||
                values.password.search(/\d/) === -1 ||
                values.password.search(/[A-Z]/) === -1 ||
                values.password.search(/[a-z]/) === -1
              ) {
                errors.password = "Utilizza una password più complessa: lunghezza minima 6 caratteri utilizzando lettere maiuscole, minuscole e numeri."
              }

              
              if ("undefined" !== typeof(values.ripeti_password) && values.password !== values.ripeti_password && values.ripeti_password.length >= 6) {
                errors.password = "Le due password non coincidono.";
              }

              

              // return errors;
              return errors;
            }}

            onSubmit={(values, { setSubmitting }) => {

              setTimeout(() => {
     
                sessionStorage.setItem(form_name,JSON.stringify(values))
                let stato_sezioni = JSON.parse(sessionStorage.getItem(ssprefix+"statosezioni"));
                stato_sezioni[4] = true;
                sessionStorage.setItem(ssprefix+"statosezioni",JSON.stringify(stato_sezioni))
     
                setSubmitting(false);

                navigate("./privacy")
     
              }, 400);
     
            }}>
              {
                (props) => (
                  <Form>
                    <div className="row">                     
                      <div className="col-12 mt-3">
                        <div className="form-group">
                          <label>Email</label>
                          <Field required type="email" name="email" disabled={true} className="form-control"/>
                          <small>Ricordati di usare questo indirizzo email per accedere a EduCO</small>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="form-group">
                          <label>Password</label>
                          <Field required type="password" name="password" className="form-control"/>
                         <small>Scegli una password sicura per proteggere il tuo account.</small>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="form-group">
                          <label>Ripeti password</label>
                          <Field required type="password" name="ripeti_password" className="form-control"/>
                         <small></small>
                        </div>
                      </div>

                      <div className="col-md-3 mt-3">
                        <button type="submit" className="btn btn-primary" disabled={props.isSubmitting}>
                          { props.isSubmitting ? 
                             <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Invio...</>
                            :
                            <><i className="bi bi-arrow-right"></i> Continua</>
                        }
                        </button>
                      </div>
                      
                      <div className="col-md-9 mt-3" style={{minHeight:"4rem",fontSize:".85rem"}}>
                      <ErrorMessage name="password" component="div" className="alert alert-danger"/>
                      </div>

                    </div>
                  </Form>
                )
              }
            </Formik>
            </div>
          </div>

          <p style={{fontSize:".9rem"}} className="mt-3">La registrazione a EduCO è gratuita. Tieni a mente la password che scegli: dovrai utilizzarla per accedere al tuo account.</p>
          
        </div>
    )

    const gia_registrato = (
      <div class="sp_form">
        <h2>Accedi al tuo account</h2>
        <p>È già presente un account EduCO associato a <strong>{emaildocente}</strong>.<br/>Effettua l'accesso per confermare la tua identità.</p>

        
        <div className="card ">
          <div className="card-body">
           
          
        <Formik
          initialValues={initial}
          validate={values => {
            // const errors = {}
            
            // if (values.password.length < 5) {
            //   errors.password = "Password troppo corta!";
            // }

            // return errors;
            return {};
          }}

          onSubmit={(values, { setSubmitting }) => {

            // setTimeout(() => {
   
            //   sessionStorage.setItem(form_name,JSON.stringify(values))
            //   let stato_sezioni = JSON.parse(sessionStorage.getItem(ssprefix+"statosezioni"));
            //   stato_sezioni[4] = true;
            //   sessionStorage.setItem(ssprefix+"statosezioni",JSON.stringify(stato_sezioni))
   
            //   

            //   navigate("./privacy")
   
            // }, 400);

            fetch(endpoint + "&task=apply.checkPwd&email="+emaildocente+"&password="+btoa(values.password))
            .then(response => {
              if (!response.ok) {
                alert("Errore");
              } else  {
                return response.json();
              }
            })
            .then(data => {
              setSubmitting(false);
              if (data.auth === true) {
                sessionStorage.setItem(form_name,JSON.stringify(values))
                  let stato_sezioni = JSON.parse(sessionStorage.getItem(ssprefix+"statosezioni"));
                  stato_sezioni[4] = true;
                  sessionStorage.setItem(ssprefix+"statosezioni",JSON.stringify(stato_sezioni))
                
                  setFeedbackAuth(<div className="alert alert-success m-3">Login effettuato correttamente.</div>)


                 
                  setTimeout(() => { setSubmitting(false); navigate("./privacy") }, 1000)
                  
              } else {
                  setFeedbackAuth(<div className="alert alert-danger m-3">La password non è corretta.</div>)
              }
            });
   
          }}>
            {
              (props) => (
                <Form>
                  <div className="row">                     
                    <div className="col-md-6 mt-3">
                      <div className="form-group">
                        <label>Email</label>
                        <Field required type="email" name="email" disabled={true} className="form-control"/>
                       <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                      </div>
                    </div>

                    <div className="col-md-6 mt-3">
                      <div className="form-group">
                        <label>Password</label>
                        <Field required type="password" name="password" className="form-control"/>
                       <ErrorMessage name="password" component="div" className="invalid-feedback"/>
                      </div>
                    </div>

                    <div className="col-12 mt-3">
                      <button type="submit" className="btn btn-primary" disabled={props.isSubmitting}>
                        { props.isSubmitting ? 
                           <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Invio...</>
                          :
                          <><i className="bi bi-box-arrow-in-right"></i> Login</>
                      }
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }
          </Formik>
          </div>
          <div className="card-footer py-3">
            <a href="https://educo.distrettoalpiorientali.it/recupero-password" target="_blank">Ho dimenticato la password</a>
          </div>
        </div>

        <p style={{fontSize:".9rem"}} className="mt-3">La registrazione a EduCO è gratuita. Se hai scelto <b>EduCO</b> tra le attività didattiche per le tue classi, dovrai accedere al tuo account per visualizzare i <b>codici classe</b> da comunicare ai tuoi alunni. Se invece hai scelto altre attività didattiche, potrai comunque sfogliare il catalogo delle risorse didattiche EduCO e scoprire i corsi di formazione per docenti.</p>
      </div>
  )

    return (
      <div>
      {contenuto}
      {feedbackAuth}
      </div>)
}