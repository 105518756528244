import {navigate} from 'hookrouter';
import {ErrorMessage, Formik,  Form, Field} from 'formik';

import { ssprefix } from './Config';

export default function Scuola(params) {

  const form_name = ssprefix+"anagraficadocente";
  let initial = {nome : '', cognome : '', email : '', telefono : ''};
  if (sessionStorage.getItem(form_name) !== null) {
    initial = JSON.parse(sessionStorage.getItem(form_name));
  }
  

    return(
        <div className="sp_form">
          <h2 className="d-none d-md-block">Dati anagrafici del docente</h2>
          <p>Adesso ci servono alcune informazioni su di te.</p>

          
          <div className="card ">
            <div className="card-body">
             
            
          <Formik
            initialValues={initial}
            validate={values => {
              // const errors = {}
              
              // if (values.password.length < 5) {
              //   errors.password = "Password troppo corta!";
              // }

              // return errors;
              return {};
            }}

            onSubmit={(values, { setSubmitting }) => {

              setTimeout(() => {
     
                sessionStorage.setItem(form_name,JSON.stringify(values))
                let stato_sezioni = JSON.parse(sessionStorage.getItem(ssprefix+"statosezioni"));
                stato_sezioni[2] = true;
                sessionStorage.setItem(ssprefix+"statosezioni",JSON.stringify(stato_sezioni))
     
                setSubmitting(false);

                navigate("/"+params.uid+"/classi")
     
              }, 400);
     
            }}>
              {
                (props) => (
                  <Form>
                    <div className="row">                     
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nome</label>
                          <Field required type="text" name="nome" className="form-control"/>
                         <ErrorMessage name="nome" component="div" className="invalid-feedback"/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Cognome</label>
                          <Field required type="text" name="cognome" className="form-control"/>
                         <ErrorMessage name="cognome" component="div" className="invalid-feedback"/>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="form-group">
                          <label>Email</label>
                          <Field required type="email" name="email" className="form-control"/>
                         <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="form-group">
                          <label>Telefono</label>
                          <Field required type="tel" name="telefono" className="form-control"/>
                         <ErrorMessage name="telefono" component="div" className="invalid-feedback"/>
                        </div>
                      </div>



                      <div className="col-12 mt-3">
                        <button type="submit" className="btn btn-primary" disabled={props.isSubmitting}>
                          { props.isSubmitting ? 
                             <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Invio...</>
                            :
                            <><i className="bi bi-arrow-right"></i> Continua</>
                        }
                        </button>
                      </div>
                    </div>
                  </Form>
                )
              }
            </Formik>
            </div>
          </div>
          
        </div>
    )
}