import {navigate} from 'hookrouter';
import React, {useState, useEffect} from 'react';
import {ErrorMessage, Formik,  Form, Field} from 'formik';
import AsyncSelect from 'react-select/async';
import Select from 'react-select'


import comuni from './data/comuni.json';
import { ssprefix } from './Config';


export default function Scuola(params) {

  const form_name = ssprefix+"scuola";
  let elenco_comuni = comuni.map((row) => {return {value:row.cod_istat,label:row.comune}})
  
  const progetto = JSON.parse(sessionStorage.getItem(ssprefix+"progetto"));
  const elenco_comuni_progetto = progetto.comuni.map(x => x.toLowerCase())

  elenco_comuni = elenco_comuni.filter((com) => elenco_comuni_progetto.indexOf(com.label.toLocaleLowerCase()) > -1);

  const [msgComune, setMsgComune] = useState("");
  const [msgScuola, setMsgScuola] = useState("");
  const [canGoAhead, setCanGoAhead] = useState(false);
  const [comune, setComune] = useState(JSON.parse(sessionStorage.getItem(ssprefix+"comuneselezionato")));
  const [scuoleComune, setScuoleComune] = useState([]);
  const [scuola,setScuola] = useState(null);
  const [datiScuolaSelezionata, setDatiScuolaSelezionata] = useState({});

  const [telScuola,setTelScuola] = useState("")

  useEffect(() => {
    sessionStorage.setItem(ssprefix+"comuneselezionato",JSON.stringify(comune))
  },[comune])

  let initial = {codice_istituto : "", istituto : "", codice_plesso : "", plesso : "", indirizzo : ""};
  if (sessionStorage.getItem(form_name) !== null) {
    initial = JSON.parse(sessionStorage.getItem(form_name));
  }
  const cercaComune = (str) => {
    return elenco_comuni.filter(i => {

      if (str.length === 0) {
        return false;
      } else {
        return i.label.toLowerCase().startsWith(str.toLowerCase())
      }

    }
    ).sort((a,b) => a.label.length > b.label.length)
  };  

  const changeComune = (value) => {
    if (value === null) {
      setMsgComune("");
      setMsgScuola(<></>)
      setScuoleComune([])
      setScuola(null)
      return;
    }
    const progetto = JSON.parse(sessionStorage.getItem(ssprefix+"progetto"));
    const elenco_comuni = progetto.comuni.map(x => x.toLocaleLowerCase());
    if (elenco_comuni.indexOf(value.label.toLocaleLowerCase()) === -1) {
      setMsgComune(<div className="alert alert-danger mt-2">Attenzione: il comune selezionato non è tra i comuni interessati dal progetto <b>{progetto.titolo_progetto}</b>.</div>)
      setCanGoAhead(false)
    } else {
      setComune(value);
      setMsgComune("")
      setCanGoAhead(true);

      let url_scuole = "https://static.educo.distrettoalpiorientali.it/opendata/comuni/"+value.value+".json";
      fetch(url_scuole)
        .then((response) => response.json())
        .then((data) => {
          const d2 = data.filter(x => {
            if (x.CODICESCUOLA == x.CODICEISTITUTORIFERIMENTO && x.DESCRIZIONETIPOLOGIAGRADOISTRUZIONESCUOLA == "ISTITUTO COMPRENSIVO") return false
            if (x.DESCRIZIONETIPOLOGIAGRADOISTRUZIONESCUOLA == "SCUOLA INFANZIA") return false;
            else return true
          })
          setScuoleComune(d2)
        })

    }
  }

  const changeTelScuola = (e) => {
    setTelScuola(e.target.value)
  }

  const confermaScuolaTrovata = () => {

    const tel_scuola = document.getElementById("tel_scuola")
    if (tel_scuola.value.length === 0) {
      alert("Indicare il telefono della scuola");
      return;
    }

    const dati_scuola = JSON.parse(sessionStorage.getItem(ssprefix+"scuola"));

    dati_scuola.TELEFONOSCUOLA = tel_scuola.value;

    sessionStorage.setItem(ssprefix+"scuola",JSON.stringify(dati_scuola))

    let stato_sezioni = JSON.parse(sessionStorage.getItem(ssprefix+"statosezioni"));
    stato_sezioni[1] = true;
    sessionStorage.setItem(ssprefix+"statosezioni",JSON.stringify(stato_sezioni))
    navigate("/"+params.uid+"/anagrafica-docente")
  }

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(cercaComune(inputValue));
      }, 500);
    });

    const cercaScuola = e => {
      e.preventDefault();

      if (comune === null) {
        setMsgScuola(<div className="alert alert-info mt-3"><i className="bi bi-exclamation-triangle"></i> Selezionare prima il comune</div>)
        return;
      }

      const txt = e.target.querySelector("#input-codice-meccanografico").value;   
      let scuola_trovata = null;
      scuoleComune.forEach((scuola) => {
        
        if (scuola.CODICESCUOLA.toUpperCase() === txt.toUpperCase()) {
          scuola_trovata = scuola;
        }
      })

      if (scuola_trovata === null) {
        setMsgScuola(<div className="alert alert-danger mt-3"><i className="bi bi-exclamation-triangle"></i> Il codice inserito non corrisponde ad una scuola nel comune di {comune.label}.</div>)
      } else {

        sessionStorage.setItem(ssprefix+"scuola", JSON.stringify(scuola_trovata) )

        setMsgScuola(
          <>
          <table className="table table-sm mt-3">
            <tbody>
              <tr>
                <th>Istituto</th>
                <td>{scuola_trovata.DENOMINAZIONEISTITUTORIFERIMENTO}</td>
              </tr>
              <tr>
                <th>Plesso scolastico</th>
                <td>{scuola_trovata.DENOMINAZIONESCUOLA}</td>
              </tr>
              <tr>
                <th>Indirizzo</th>
                <td>{scuola_trovata.INDIRIZZOSCUOLA + " - " + scuola_trovata.DESCRIZIONECOMUNE}</td>
              </tr>
              <tr>
                <th>Grado scolastico</th>
                <td>{scuola_trovata.DESCRIZIONETIPOLOGIAGRADOISTRUZIONESCUOLA}</td>
              </tr>
            </tbody>
          </table>
          <button onClick={confermaScuolaTrovata} className="btn btn-primary"><i className="bi bi-arrow-right"></i> Continua</button>
          </>
        )
      }

    }

    const changeScuola = (value) => {
      
      if (comune === null) {
        setMsgScuola(<div className="alert alert-info mt-3"><i className="bi bi-exclamation-triangle"></i> Selezionare prima il comune</div>)
        return;
      }

      if (value === null) {
        setMsgScuola(<></>)
        return;
      }

      setScuola(value)

      const txt = value.value;  
      let scuola_trovata = null;
      scuoleComune.forEach((scuola) => {
        
        if (scuola.CODICESCUOLA.toUpperCase() === txt.toUpperCase()) {
          scuola_trovata = scuola;
        }
      })

      if (scuola_trovata === null) {
        setMsgScuola(<div className="alert alert-danger mt-3"><i className="bi bi-exclamation-triangle"></i> Il codice inserito non corrisponde ad una scuola nel comune di {comune.label}.</div>)
      } else {
        sessionStorage.setItem(ssprefix+"scuola", JSON.stringify(scuola_trovata) )
        setMsgScuola(
          <>
          <table className="table table-sm mt-3">
            <tbody>
              <tr>
                <th>Istituto</th>
                <td><code>{scuola_trovata.CODICEISTITUTORIFERIMENTO}</code> {scuola_trovata.DENOMINAZIONEISTITUTORIFERIMENTO}</td>
              </tr>
              <tr>
                <th>Plesso scolastico</th>
                <td><code>{scuola_trovata.CODICESCUOLA}</code> {scuola_trovata.DENOMINAZIONESCUOLA}</td>
              </tr>
              <tr>
                <th>Indirizzo</th>
                <td>{scuola_trovata.INDIRIZZOSCUOLA + " - " + scuola_trovata.DESCRIZIONECOMUNE}</td>
              </tr>
              <tr>
                <th>Grado scolastico</th>
                <td>{scuola_trovata.DESCRIZIONETIPOLOGIAGRADOISTRUZIONESCUOLA}</td>
              </tr>
              <tr>
                <th>Indirizzo email</th>
                <td>{scuola_trovata.INDIRIZZOEMAILSCUOLA}</td>
              </tr>
              <tr>
                <th>Telefono</th>
                <td>
                  <input id="tel_scuola" className="form-control form-control-sm" type="tel" placeholder="Numero telefonico della scuola" ></input>
                </td>
              </tr>
            </tbody>
          </table>
          <button onClick={confermaScuolaTrovata} className="btn btn-primary"><i className="bi bi-arrow-right"></i> Continua con la scuola <b>{scuola_trovata.CODICESCUOLA}</b></button>
          </>
        )
      }

    }

    return(
        <div className="sp_form">
          <h2 className="d-none d-md-block">La tua scuola</h2>
          <p>Per iniziare, ci servono alcune informazioni sulla tua scuola.</p>

          <div className="card">
            <div className="card-body">
              <p>In che comune è situata la scuola?</p>
              <div style={{maxWidth: "25rem"}} >
              <AsyncSelect required isClearable={true} onChange={changeComune} cacheOptions defaultOptions loadOptions={promiseOptions} loadingMessage={() => "Attendere..."} placeholder="Seleziona il comune" defaultOptions={elenco_comuni} noOptionsMessage={() => "Nessun risultato"} />
              </div>
              {msgComune}
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <p>Cerca la scuola tramite il <b>codice meccanografico</b> o il <b>nome della scuola</b> <span className="badge rounded-pill bg-primary">Scelta consigliata</span> </p>
              {/* <form onSubmit={cercaScuola} className="row">
                  <div className="col-6">
                    <input required id="input-codice-meccanografico" className="form-control" type="search" name="codice_meccanografico" placeholder="Es.: RMIC123456"></input>
                  </div>
                  <div className="col-6">
                    <button type="submit" className="btn btn-primary d-inline"><i className="bi bi-search"></i> Cerca</button>
                  </div>
              </form>
               */}
              <div style={{maxWidth: "25rem"}}>
                <Select isDisabled={scuoleComune.length===0?true:false} onChange={changeScuola} isClearable={true} options={scuoleComune.map((scuola) => {
            return {value : scuola.CODICESCUOLA, label: scuola.CODICESCUOLA + ": "+ scuola.DENOMINAZIONESCUOLA};
          })} loadingMessage={() => "Attendere..."}  placeholder="Seleziona la scuola" value={scuola} />
          </div>
          {msgScuola}
            </div>
          </div>

          <p className="text-center mt-3"><small>- oppure - </small></p>
          <div className="card ">
            <div className="card-body">
              <p>Inserisci i dati della scuola, se non è presente nell'elenco.</p>
            
          <Formik
            initialValues={initial}
            validate={values => {
              // const errors = {}
              
              // if (values.password.length < 5) {
              //   errors.password = "Password troppo corta!";
              // }

              // return errors;
              return {};
            }}

            onSubmit={(values, { setSubmitting }) => {

              setTimeout(() => {
                
                let stato_sezioni = JSON.parse(sessionStorage.getItem(ssprefix+"statosezioni"));
                stato_sezioni[1] = true;
                sessionStorage.setItem(ssprefix+"statosezioni",JSON.stringify(stato_sezioni))

                values["inserimento_manuale"] = true;
                sessionStorage.setItem(form_name,JSON.stringify(values))
                setSubmitting(false);
                navigate("/"+params.uid+"/anagrafica-docente")
     
              }, 500);
     
            }}>
              {
                (props) => (
                  <Form>
                    <div className="row">                     
                      
                    <div className="col-md-4">
                        <div className="form-group">
                          <label>Codice meccanografico istituto</label>
                          <Field required type="text" name="codice_istituto" className="form-control"/>
                         <ErrorMessage name="codice_istituto" component="div" className="invalid-feedback"/>
                        </div>
                      </div>

                      <div className="col-md-8">
                        <div className="form-group">
                          <label>Nome istituto</label>
                          <Field required type="text" name="istituto" className="form-control"/>
                         <ErrorMessage name="istituto" component="div" className="invalid-feedback"/>
                        </div>
                      </div>

                      <div className="col-md-4 mt-3">
                        <div className="form-group">
                          <label>Codice meccanografico plesso</label>
                          <Field required type="text" name="codice_plesso" className="form-control"/>
                         <ErrorMessage name="codice_plesso" component="div" className="invalid-feedback"/>
                        </div>
                      </div>

                      <div className="col-md-8 mt-3">
                        <div className="form-group">
                          <label>Plesso scolastico</label>
                          <Field required type="text" name="plesso" className="form-control"/>
                         <ErrorMessage name="plesso" component="div" className="invalid-feedback"/>
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        <div className="form-group">
                          <label>Indirizzo</label>
                          <Field required type="text" name="indirizzo" className="form-control"/>
                         <ErrorMessage name="indirizzo" component="div" className="invalid-feedback"/>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="form-group">
                          <label>Email della scuola</label>
                          <Field required type="email" name="email" className="form-control"/>
                         <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="form-group">
                          <label>Telefono della scuola</label>
                          <Field required type="tel" name="telefono" className="form-control"/>
                         <ErrorMessage name="telefono" component="div" className="invalid-feedback"/>
                        </div>
                      </div>


                      <div className="col-12 mt-3">
                        <button type="submit" className="btn btn-primary" disabled={props.isSubmitting || !canGoAhead}>
                          { props.isSubmitting ? 
                             <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Invio...</>
                            :
                            <><i className="bi bi-arrow-right"></i> Continua con i dati inseriti manualmente</>
                        }
                        </button>
                      </div>
                    </div>
                    
                                       
                   
                  </Form>
                )
              }
            </Formik>
            </div>
          </div>
        </div>
    )
}