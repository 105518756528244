import {navigate} from 'hookrouter';
import { ssprefix } from './Config';
import {Formik,  Form, Field} from 'formik';


export default function Privacy(params) {

  const form_name = ssprefix+"privacy";
  let initial = {};
  if (sessionStorage.getItem(form_name) !== null) {
    initial = JSON.parse(sessionStorage.getItem(form_name));
  }

  return(
    <div class="sp_form">
   
      <Formik
        initialValues={initial}
        validate={values => {
          // const errors = {}
          
          // if (values.password.length < 5) {
          //   errors.password = "Password troppo corta!";
          // }

          // return errors;
          return {};
        }}

        onSubmit={(values, { setSubmitting }) => {

          setTimeout(() => {
 
            sessionStorage.setItem(form_name,JSON.stringify(values))
            let stato_sezioni = JSON.parse(sessionStorage.getItem(ssprefix+"statosezioni"));
            stato_sezioni[5] = true;
            sessionStorage.setItem(ssprefix+"statosezioni",JSON.stringify(stato_sezioni))
 
            setSubmitting(false);

            navigate("./riepilogo")
 
          }, 400);
 
        }}>
          {
            (props) => (
              <Form>
               

            <h2>Autorizzazione al trattamento dei dati personali</h2>
            <p>Il tuo consenso è obbligatorio per noi per poter raccogliere i dati necessari all'iscrizione della tua classe (il tuo nominativo, indirizzo mail e numero di telefono personali) e per poterti inviare le successive comunicazioni per la programmazione delle attività didattiche.</p>

            <div className="card">
                <div className="card-body">
                    <div className="form-check">
                         <Field type="checkbox" name="autorizz-privacy" value="ok" id="autorizz-privacy" required className="form-check-input" />
                        <label className="form-check-label" for="autorizz-privacy">
                        Dichiaro di aver preso visione di tutte le informazioni relative al progetto e di aver letto l'offerta didattica contenente le attività proposte
                        </label>
                    </div>

                    <div className="form-check mt-3">
                        <Field type="checkbox" name="ack-priorita" value="ok" id="ack-priorita" required className="form-check-input" />
                        <label className="form-check-label" for="ack-priorita">
                        Dichiaro di aver preso atto e compreso che i posti per le attività didattiche sono limitati e che sarà tenuto in considerazione l'ordine di registrazione delle adesioni.
                        </label>
                    </div>
                   
                
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">

                    <p style={{fontSize:".85rem"}}>I dati personali conferiti a questa Amministrazione sono trattati nel rispetto dei principi di liceità, correttezza, trasparenza, limitazione della finalità, minimizzazione dei dati, esattezza, limitazione della conservazione, integrità e riservatezza, nonché delle libertà fondamentali e, in ogni caso, in conformità alla normativa di settore vigente in particolare alle prescrizioni contenute nel Regolamento U.E. 2016/679 "Regolamento Generale sulla Protezione dei Dati" (di seguito GDPR), divenuto applicabile dal 25.05.2018. A tal fine si comunica che il titolare del trattamento dei dati è l'Autorità di bacino distrettuale delle Alpi Orientali con sede a Venezia, Cannaregio, n. 4314 in persona del Segretario generale, ing. Francesco Baruffi, e che il titolare del trattamento ha predisposto misure tecniche e organizzative adeguate a garantire che il trattamento dei dati personali avvenga conformemente al predetto GDPR, tenuto conto della natura, dell'ambito di applicazione, del contesto e delle finalità del trattamento, nonché dei rischi aventi probabilità e gravità diverse per i diritti e le libertà delle persone fisiche. Si pubblicano ai sensi dell'art. 37 par. 7 del GDPR i dati di contatto del responsabile della protezione dei dati (RPD) nominato con Decreto del Segretario Generale n. 44 del 21.5.2018.
Responsabile protezione dati avv. Cesare Lanna
Tel: 041-3096000
Email: cesare.lanna@distrettoalpiorientali.it
Pec: segreteria.adbve@legalmail.it
Consenso dell’interessato al trattamento dei propri dati personali ex art 13 GDPR </p>
                    <div className="form-check">
                        <Field type="checkbox" name="autorizz-gdpr" value="ok" id="autorizz-gdpr" required className="form-check-input" />
                        <label className="form-check-label" for="autorizz-gdpr">
                        Accetto
                        </label>
                    </div>

                
                </div>
            </div>

            <p className="mt-3"><button type="submit" className="btn btn-primary"><i className="bi bi-arrow-right"></i> Vai al riepilogo</button></p>

              </Form>
            )
          }
        </Formik>      
    </div>
)

}