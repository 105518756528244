import {  ssprefix } from "./Config";
import NotFound from "./NotFound";
import {useEffect, useState} from 'react';
import {ErrorMessage, Formik,  Form, Field} from 'formik';
import {A, navigate} from 'hookrouter'


export default function Classe(props) {
    const idc = props.idc;

    const [classi,setClassi] = useState(JSON.parse(sessionStorage.getItem(ssprefix+"elencoclassi")))
    // const [domandeCustom, setDomandeCustom] = useState(null);

    const domandeCustom = JSON.parse(sessionStorage.getItem(ssprefix + "domandecustom"))

    useEffect(() => {
        sessionStorage.setItem(ssprefix+"elencoclassi", JSON.stringify(classi));
    },[classi])

    

    if (typeof(classi[idc]) === "undefined") return (
        <NotFound/>
    )

    const DomandeCustom = () => {

        if (domandeCustom === null) {
            return (<small>Caricamento delle domande...</small>)
        }

        if (domandeCustom.length === 0) {
            return (<></>)
        } else {
            return domandeCustom.map((domanda,key) => {
                let formelement; 
                if (["text","number","tel","email"].indexOf(domanda.type) > -1) {
                   formelement = (
                       <div className="form-group">
                        <label>{domanda.question}</label>
                        <Field required={domanda.required} type={domanda.type} name={"custom-"+key} className="form-control"/>
                        </div>
                   )
                } else {
                    if (domanda.type === "textarea") {
                        formelement = (
                            <div className="form-group">
                             <label>{domanda.question}</label>
                             <Field required={domanda.required} as="textarea" name={"custom-"+key} className="form-control"/>
                             </div>
                        )
                    }

                    if (domanda.type === "checkbox") {
                      //   formelement = (<div className="form-check">
                      //  <Field required={domanda.required} type="checkbox" name={"custom-"+key} id={"custom-"+key} className="form-check-input"/>
                      //   <label className="form-check-label" htmlFor={"custom-"+key}>
                      //       {domanda.question}
                      //   </label>
                      // </div>)


                        let options = domanda.options.map((option, subkey) => (<div className="form-check">
                        <Field required={domanda.required} type="checkbox" name={"custom-"+key} value={"custom-"+key+"-"+subkey} id={"custom-"+key+"-"+subkey} className="form-check-input"/>
                         <label className="form-check-label" htmlFor={"custom-"+key+"-"+subkey}>
                             {option}
                         </label>
                       </div>));

                       formelement = (<>
                        {domanda.question}
                        <div class="my-3"></div>
                        {options}
                       </>)
                    }

                    if (domanda.type === "radio") {
                        let options = domanda.options.map((option, subkey) => (<div className="form-check">
                        <Field required={domanda.required} type="radio" name={"custom-"+key} value={"custom-"+key+"-"+subkey} id={"custom-"+key+"-"+subkey} className="form-check-input"/>
                         <label className="form-check-label" htmlFor={"custom-"+key+"-"+subkey}>
                             {option}
                         </label>
                       </div>));

                       formelement = (<>
                        {domanda.question}
                        <div class="my-3"></div>
                        {options}
                       </>)
                        
                    }

                    if (domanda.type === "paragraph") {
                        formelement = <p>{domanda.content}</p>
                    }
                    if (domanda.type === "heading") {
                        formelement = <h4>{domanda.content}</h4>
                    }

                }

                return (<div className="col-12 mt-3">
                    {formelement}
                    <small>{domanda.info}</small>
                    <ErrorMessage name={"custom-"+key} component="div" className="invalid-feedback"/>
                </div>)

            })
        }

    }

    return (

        <div class="sp_form">

            <A href={"../classi"} className="btn btn-sm btn-outline-secondary"><i className="bi bi-arrow-left"></i> Torna all'elenco delle classi</A>

            <hr/>

            <h2>Classe {}</h2>
            <p>Inserisci i dati della classe e scegli le attività didattiche.<br/>Se non sei ancora a conoscenza del numero esatto di alunni, inserisci un numero indicativo.</p>
            
            <Formik
            initialValues={classi[idc]}
            validate={values => {
              // const errors = {}
              
              // if (values.password.length < 5) {
              //   errors.password = "Password troppo corta!";
              // }

              // return errors;
              return {};
            }}

            onSubmit={(values, { setSubmitting }) => {

           

            let c2 = Object.assign([],classi);
            c2[idc] = values;
            setClassi(c2);

              setTimeout(() => {
     
         
                setSubmitting(false);

                navigate("../classi")
     
              }, 1000);
     
            }}>
              {
                (props) => (
                  <Form>
                    <div className="row">                     
                      <div className="col-lg-4 mt-2">
                        <div className="form-group">
                          <label>Classe e sezione</label>
                          <Field required type="text" name="classe" placeholder="Esempio: 1ªD" className="form-control"/>
                        <ErrorMessage name="classe" component="div" className="invalid-feedback"/>
                        </div>
                      </div>

                      <div className="col-lg-3 mt-2">
                        <div className="form-group">
                          <label>Numero di alunni</label>
                          <Field required type="number" min="1" max="30" name="nalunni" className="form-control"/>
                         <ErrorMessage name="nalunni" component="div" className="invalid-feedback"/>
                        </div>
                      </div>

                      <div className="col-lg-5 mt-2">
                        <div className="form-group">
                          <label>Grado scolastico</label>
                          <Field required as="select" name="grado_scolastico" className="form-select">
                            <option>-</option>
                            <option value="2">Primaria</option>
                            <option value="3">Secondaria di 1° grado</option>
                            <option value="4">Secondaria di 2° grado</option>
                          </Field>
                         <ErrorMessage name="grado_scolastico" component="div" className="invalid-feedback"/>
                        </div>
                      </div>

                      <DomandeCustom></DomandeCustom>

                    
                      <div className="col-12 mt-3">
                        <button type="submit" className="btn btn-primary" disabled={props.isSubmitting}>
                          { props.isSubmitting ? 
                             <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Salvataggio...</>
                            :
                            <><i className="bi bi-check"></i> Salva</>
                        }
                        </button>
                      </div>
                    </div>
                    
                                       
                   
                  </Form>
                )
              }
            </Formik>


        </div>

    )
}