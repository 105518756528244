import {A,usePath} from 'hookrouter'

export default function NotFound() {

    const path = usePath();

    return (
        <div className="text-center">
            <h1>Oooops!</h1>
            <p>Non riusciamo a trovare la sezione <b>{path}</b>.</p>

            <A href="/">Torna all'inizio</A>
        </div>
    )
}